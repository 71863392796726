<template>
    <div>
        <div class="p-t-10 text-center ">
          <div v-for="tag in tagList" :key="tag.key"  class="p-l-20 p-r-20 m-b-2"
            @click="handleChangeTag(tag.key)" :class="currentTag=== tag.key?'workplatform-radio-button-active':'workplatform-radio-button'">
            {{tag.name}}</div>
        </div>

        <Divider dashed><span class="divider-text">{{tagName}}{{pageType==='reconciliation'?'对账':'结算'}}</span></Divider>
        <component :is="createComponent" @on-create="handleCreate"></component>
    </div>
</template>

<script>
export default {
  components: {
    transactionreconciliationComponent: () => import('./components/transactionReconciliation'),
    maintenancereconciliationComponent: () => import('./components/maintenanceReconciliation'),

    transactionsettlementComponent: () => import('./components/transactionSettlement'),
    maintenancesettlementComponent: () => import('./components/maintenanceSettlement')
    // CreateAgreement: () => import('./components/CreateAgreement'),
    // CreateContractChange: () => import('./components/CreateContractChange'),
    // CreateStatementChange: () => import('./components/CreateStatementChange'),
    // CreateInvoice: () => import('./components/CreateInvoice'),
    // CreateStatementInvoice: () => import('./components/CreateStatementInvoice'),
    // CreateStatement: () => import('./components/CreateStatement')
  },
  data () {
    return {
      createComponent: '',
      currentTag: 0,
      tagName: '',
      tagList: [
        // { key: 1, name: '交易' },
        // { key: 2, name: '运维' }
      ]
    }
  },
  created () {
    if (this.isAuth('transaction_view')) {
      this.tagList.push({ key: 1, name: '交易' })
    }
    if (this.isAuth('maintenance_view')) {
      this.tagList.push({ key: 2, name: '运维' })
    }
    if (this.moduleType === 'transaction' && this.tagList.filter(x => x.key === 1).length) {
      this.currentTag = 1
    } else if (this.moduleType === 'maintenance' && this.tagList.filter(x => x.key === 2).length) {
      this.currentTag = 2
    } else {
      this.currentTag = this.tagList[0].key
    }
    // if (this.currentTag) {

    // } else {
    //   this.currentTag = this.tagList[0].key
    // }

    this.handleChangeTag(this.currentTag)
  },
  methods: {
    handleChangeTag (tag) {
      this.currentTag = tag
      this.tagName = this.tagList.find(x => x.key === tag).name
      let moduleType = ''

      switch (tag) {
        case 1:
          moduleType = 'transaction' // 交易
          break
        case 2:
          moduleType = 'maintenance' // 运维
          break
        default:
          break
      }

      this.createComponent = moduleType + this.pageType + 'Component'
      this.$store.commit('set_reconciliationNew_moduleType', moduleType)
    },
    handleCreate (baseId) {
      this.$emit('on-create', baseId)
    }
  },
  computed: {
    pageType () {
      return this.$store.state.reconciliationNew ? this.$store.state.reconciliationNew.pageType : ''
    },
    moduleType () {
      return this.$store.state.reconciliationNew ? this.$store.state.reconciliationNew.moduleType : 'transaction'
    }
  },
  watch: {
    pageType () {
      this.handleChangeTag(this.currentTag)
    }
  }
}
</script>
